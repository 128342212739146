<template>
    <main class="view">
        <div class="dashboard-wrapper">
            <div class="dashboard-actions">
                <Select 
                    name="Dashboard"
                    mode="single"
                    :list="list"
                    :selected="[selected]"
                    @update="update" />
            </div>
            <router-view></router-view>
        </div>
    </main>
</template>

<script>
import { BPA } from '@/helpers/BPA';
import Select  from '@/components/snippets/Select';

export default {
    name: "DashboardLive",
    mixins: [BPA],
    components: {
        Select
    },
    data() {
        return {
            list: {
/*              'dashboard-live': 'Live preview',
                'dashboard-packer': 'Packer',
                'dashboard-picker': 'Picker',
                'dashboard-carrier': 'Carrier',
                'dashboard-orders': 'Orders', */
                'dashboard-deadline': 'Deadlines',
            }
        };
    },
    computed: {
        selected: function () {
            return this.$route.name;
        }
    },
    methods: {
        update(changed) {
            if (this.$route.name !== changed[0]) {
                this.$router.push({ name: changed[0] });
            }
        }
    }
};
</script>

<style scoped>
</style>
